import logo from './title.png';
import './App.css';
import {WallLeft, WallRight, Cellphone} from './Wall.js';
import {Dialog} from './Dialog.js';
import {HashRouter, Route, Routes, Link as RLink, useParams} from "react-router-dom";
import "./fonts/PixeloidSans.ttf";

function getSpeciesById(speciesId) {
    return speciesList.find((species) => species.speciesId === speciesId);
}

const speciesList = [
    {
        "speciesId" : "pez_linterna",
        "speciesName" : "Pez linterna",
        "speciesScientificName" : "Symbolophorus barnardi",
        "dataDescription" : "",
        "video": "https://youtu.be/iPIwZ6AMq9A"
    },
    {
        "speciesId" : "medusa_fantasma",
        "speciesName" : "Medusa fantasma gigante",
        "speciesScientificName" : "Stygiomedusa gigantea",
        "dataDescription" : "",
        "video": "https://youtu.be/9bzQYKm3xTA?t=2"
    },
    {
        "speciesId" : "bufoceratias_shaoi",
        "speciesName" : "Bufoceratias",
        "speciesScientificName" : "Bufoceratias shaoi",
        "dataDescription" : "",
        "video": "https://youtu.be/HCOKZ-YSjmI?t=214"
    },
    {
        "speciesId": "anoplogaster_cornuta",
        "speciesName": "Pez con colmillos largos",
        "speciesScientificName": "Anoplogaster cornuta",
        "video": "https://youtube.com/shorts/6HIW464A81k"
    },
    {
        "speciesId" : "argyropelecus_hemigymnus",
        "speciesName": "Pez hacha ganchudo",
        "speciesScientificName" : "Argyropelecus hemigymnus",
        "video": "https://youtu.be/fR1jl1qRPcU?t=2"
    },
    {
        "speciesId": "chiasmodon_subniger",
        "speciesName" : "Engullidor negro",
        "speciesScientificName": "Chiasmodon subniger",
        "video": "https://youtu.be/ryRcPeOM1sY?t=378"

    },
    {
        "speciesId" : "cachalote",
        "speciesName" : "Cachalote",
        "speciesScientificName" : "Physeter macrocephalus",
        "dataDescription" : "",
        "video": "https://youtu.be/uOCsWbrySok?t=8"
    },
    {
        "speciesId": "coryphaenoides_spp",
        "speciesName" : "Pez cola de rata",
        "speciesScientificName": "Coryphaenoides",
        "video": "https://youtu.be/ryRcPeOM1sY?t=253"
    },
    {
        "speciesId" : "dosidicus_gigas",
        "speciesName" : "Calamar gigante",
        "speciesScientificName" : "Dosidicus gigas",
        "video": "https://youtu.be/ryRcPeOM1sY?t=313"
    },
    {
        "speciesId": "harriotta_raleighana",
        "speciesName" : "Quimera picuda",
        "speciesScientificName": "Harriotta raleighana",
        "video": "https://youtu.be/ryRcPeOM1sY?t=441"
    },
    {
        "speciesId" : "histioteuthis_heteropsis",
        "speciesName" : "Calamar frutilla",
        "speciesScientificName" : "Histioteuthis heteropsis",
        "video": "https://youtu.be/ryRcPeOM1sY?t=155"
    },
    {
        "speciesId": "japetella_diaphana",
        "speciesName" : "Pulpo japetella",
        "speciesScientificName": "Japetella diaphana",
        "video": "https://youtu.be/ryRcPeOM1sY?t=50"

    },
    {
        "speciesId" : "chaunax_pictus",
        "speciesName" : "Bostezador rosado",
        "speciesScientificName" : "Chaunax pictus",
        "video": "https://www.youtube.com/watch?v=hyP5hJM9qIg"
    },
    {
        "speciesId": "notobranchaea_macdonaldi",
        "speciesName" : "Ángel marino oso panda",
        "speciesScientificName": "Notobranchaea macdonaldi",
        "video": "https://youtu.be/ryRcPeOM1sY?t=546"
    },
    {
        "speciesId" : "paraliparis_selti",
        "speciesName" : "Pez caracol azul de Atacama",
        "speciesScientificName" : "Paraliparis selti",
        "video": "https://youtu.be/sZYXomGHGJ0?t=5"
    },
    {
        "speciesId" : "periphylla_periphylla",
        "speciesName" : "Medusa casco",
        "speciesScientificName": "Periphylla periphylla",
        "video": "https://youtu.be/ryRcPeOM1sY?t=327"
    },
    {
        "speciesId" : "taonius_sp",
        "speciesName" : "Calamar cacatúa",
        "speciesScientificName" : "Taonius",
        "video": "https://youtu.be/M-0V1xJJR58"
    },
    {
        "speciesId" : "alicella_gigantea",
        "speciesName" : "Anfípodo supergigante",
        "speciesScientificName" : "Alicella gigantea",
        "video": "https://youtu.be/vioNKESxLmM?t=3"
    },
    {
        "speciesId" : "caulophryne_jordani",
        "speciesName" : "Caulofrino con aletas de abanico",
        "speciesScientificName" : "Caulophryne jordani",
        "video" : "https://youtu.be/anDIlMVgNwk?t=2"
    },
    {
        "speciesId" : "chauliodus_sloani",
        "speciesName" : "Pez víbora de Sloane",
        "speciesScientificName" : "Chauliodus sloani",
        "video" : "https://youtu.be/gj5AXQ6v7tM?t=1"
    },
    {
        "speciesId" : "cryptopsaras_couesii",
        "speciesName" : "Demonio marino de triple verruga",
        "speciesScientificName" : "Cryptopsaras couesii",
        "video" : "https://youtu.be/ht059ih_SwM"
    },
    {
        "speciesId" : "amperima_holothurian",
        "speciesName" : "Pepino de mar",
        "speciesScientificName": "Amperima holothurian",
        "video": "https://youtu.be/UEBqUrYJlsA?t=66"
    },
    {
        "speciesId" : "anfi_atacama",
        "speciesName" :  "Anfípodo gigante de Atacama",
        "speciesScientificName" : "Eurythenes atacamensis",
        "dataDescription" : "Los Anfípodos son de los crustáceos más diversos y abundantes que existen. Además, se encuentran en casi todos los ambientes, desde la superficie del mar hasta las extremas condiciones del océano ultra profundo. Lo increíble es que allí, en lo más profundo, estos custáceos son gigantes (entre 8 a 10 cm). Eurythenes atacamensis es el anfípodo que habita únicamente en la Fosa de Atacama.",
        "video" : "https://youtu.be/qEpD5jKr5Tw?t=718"
    },
    {
        "speciesId" : "culeolus_sp",
        "speciesName" : "Culeolus",
        "speciesScientificName" : "Culeolus",
        "video": "https://www.youtube.com/watch?v=qO6nYBS6CZQ&t=18s"
    },
    {
        "speciesId" : "elpidia_glacialis",
        "speciesName" :  "Cerdo de mar",
        "speciesScientificName" : "Elpidia glacialis",
        "dataDescription" : "Estos alargados pepinos de mar de las profundidades oceánicas tienen unos apéndices que ¡actúan como verdaderas piernas! Con estas pueden caminar por el fondo marino, inflándolos y desinflándolos a medida que cambian el flujo de agua. La boca está rodeada de tentáculos cortos, pero gruesos, que le sirven para alimentarse. Se conocen comúnmente como “cerdos de mar”.",
        "video": "https://youtu.be/m-H1J7ukGsU"
    },
    {
        "speciesId" : "pez_dumbo_pequeño",
        "speciesName" : "Pulpo dumbo pequeño",
        "speciesScientificName": "Grimpoteuthis",
        "video": "https://www.youtube.com/watch?v=DmqikqvLLLw"
    },
    {
        "speciesId" : "pulpo_dumbo_emperador",
        "speciesName" :  "Pulpo dumbo emperador",
        "speciesScientificName" : "Grimpteuthis imperator",
        "dataDescription" : "Este molusco fue nombrado como “Pulpo Dumbo” por sus aletas ubicadas en la zona superior del cuerpo, muy parecidas a las orejas voladoras del elefante de Walt Disney. Son criaturas que viven cerca de los fondos oceánicos en las extremas condiciones de las profundidades marinas.",
        "video" : "https://youtu.be/ayCdTH7k6eU?t=70",
        "other" : {
            "speciesScientificName": "Grimpoteuthis sp"
        }
    },
    {
        "speciesId" : "grammatostomias_flagellibarba",
        "speciesName" : "Pez dragón barbudo",
        "speciesScientificName" : "Grammatostomias flagellibarba",
        "video" : "https://youtu.be/i6cIoj7c2FI?t=30"
    },
    {
        "speciesId": "hexanchus_griseus",
        "speciesName": "Cañabota gris",
        "speciesScientificName": "Hexanchus griseus",
        "video" : "https://youtu.be/ogk-r15IWIE"
    },
    {
        "speciesId" : "medusa_bioluminiscente",
        "speciesName" :  "Medusa bioluminiscente",
        "speciesScientificName" : "Crossota sp",
        "dataDescription" : "Estas pequeñas medusas que habitan en el océano profundo son bioluminiscentes, es decir que pueden producir su propia luz, la que les sirve como medio de defensa o advertencia a otras criaturas, y así evitar a los depredadores. Se caracterizan por tener un borde similar a un paraguas con largos, delgados y numerosos tentáculos.",
        "video": "https://youtu.be/ayCdTH7k6eU?t=70"
    },
    {
        "speciesId" : "macropinna_microstoma",
        "speciesName" :  "Pez duende",
        "speciesScientificName": "Macropinna microstoma",
        "video": "https://youtu.be/RM9o4VnfHJU?t=27",
    },
    {
        "speciesId" : "poralia_rufescens",
        "speciesName" : "Medusa disco rojo",
        "speciesScientificName": "Poralia rufescens",
        "video": "https://youtu.be/wQQtjQ8HFBI?t=18"
    },
    {
        "speciesId" : "pez_babosa",
        "speciesName" :  "Pez babosa",
        "speciesScientificName" : "Pseudoliparis swirei",
        "dataDescription" : "Los peces babosos tienen el cuerpo alargado, con un aspecto gelatinoso y piel desnuda, es decir, sin escamas. Tienen un color muy hermoso, púrpura azulado. Estos peces tienen el récord de ser los peces descubiertos que pueden sobrevivir a mayor profundidad en el océano. En la Fosa de Atacama se han observado a 8.000 m de profundidad.",
        "video": "https://youtu.be/iIIB771kP-0?t=2"
    },
    {
        "speciesId" : "enypniastes_eximia",
        "speciesName" :  "Pepino de mar nadador",
        "speciesScientificName" : "Enypniastes eximia",
        "dataDescription" : "Este curioso espécimen, pariente de las estrellas de mar y los erizos, fue encontrado por científicos australianos y lo bautizaron como el \"monstruo de pollo sin cabeza\". Es transparente, gelatinoso y bioluminiscente. Es una extraña criatura que hoy navega por las profundidades del mar cerca de la Antártica.",
        "video": "https://youtu.be/ryRcPeOM1sY?t=95"

    },
    {
        "speciesId" : "psychropotes_holothurian",
        "speciesName" : "Pepino de mar",
        "speciesScientificName" : "Psychropotes holothurian",
        "video": "https://youtu.be/f2hzP4hXrl4?t=32"
    },
    {
        "speciesId" : "psychropotes_longicauda",
        "speciesName" : "Ardilla gomosa",
        "speciesScientificName" : "Psychropotes longicauda",
        "video": "https://oceanexplorer.noaa.gov/explorations/18ccz/media/ccz_video_070518/DeepCCZexpedition2018_video_640x360.mp4?640"
    },
    {
        "speciesId" : "halomonas_titanicae",
        "speciesName" :  "Halomonas titanicae",
        "speciesScientificName" : "Halomonas titanicae",
        "dataDescription" : "La Halomonas titanicae es una bacteria que fue descubierta en los restos del Titanic, en el años 2010. Esta bacteria se alimenta de hierro y es responsable de la rápida y acelerada oxidación y descomposición de los restos del emblemático barco.",
        "video": "https://youtu.be/Qpb8u1iRUwE?t=28"
    },
    {
        "speciesId" : "pez_rape",
        "speciesName" :  "Pez rape",
        "speciesScientificName" : "Melanocetus johnsonii",
        "dataDescription" : "Como pez rape se conoce a una gran cantidad de especies de peces con algunas características muy particulares. Poseen cabezas anchas, cuerpos aplanados, bocas enromes con grandes dientes, pero lo que más los distingue es una extensión larga que sobresale por encima de la boca y que utiliza como señuelo o carnada para atraer presas. Este señuelo tiene un órgano luminoso, gracias a unas bacterias que producen su propia luz.",
        "video": "https://youtu.be/ryRcPeOM1sY?t=63"
    },
    {
        "speciesId" : "pez_gota",
        "speciesName" :  "Pez gota",
        "speciesScientificName" : "Psychrolutes marcidus",
        "dataDescription" : "El pez gota ha sido bautizado como el pez más feo del mundo. Esto se debe a la fotografía que circula de él donde se ve como un viejito feo y arrugado. Sin embargo, esa imagen se debe a lo que le ocurre a su cuerpo, que es prácticamente agua, cuando lo sacan de su ambiente. Su cuerpo está adaptado a grandes presiones, en el océano profundo, lugar donde su cuerpo no parece derretido.",
        "video": "https://youtu.be/ryRcPeOM1sY?t=35"
    },
    {
        "speciesId" : "calamar_vampiro",
        "speciesName" :  "Calamar vampiro",
        "speciesScientificName" : "Vampyroteuthis infernalis",
        "dataDescription" : "El calamar vampiro es un molusco cefalópodo, único especimen del orden Vampyromorphida. Tiene un cuerpo gelatinoso, con colores que van de negro intenso a rojizo. Estos rasgos, así como su manto que parece capa y sus ojos rojos, le dieron su peculiar nombre. Está adaptado a vivir en zonas con muy poco contenido de oxígeno.",
        "video" : "https://youtu.be/GMqlRbj9bBw?t=8"
    },
    {
        "speciesId" : "regalecus_glesne",
        "speciesName": "Pez remo gigante",
        "speciesScientificName": "Regalecus glesne",
        "video" : "https://youtu.be/hzW5Jx3fybM?t=281"
    },
    {
        "speciesId" : "eurypharynx_pelecanoides",
        "speciesName" : "Pez pelícano",
        "speciesScientificName" : "Eurypharynx pelecanoides",
        "video": "https://youtu.be/1vtJGr8HO1I?t=64"
    },
    {
        "speciesId" : "freyastera_tuberculata",
        "speciesName" : "Estrella de mar profunda",
        "speciesScientificName" : "Freyastera tuberculata",
        "video": "https://youtu.be/kwcLrCLT0DY?t=9"
    },
    {
        "speciesId" : "calamar_luciernaga",
        "speciesName" : "Calamar luciérnaga",
        "speciesScientificName" : "Watasenia scintillans",
        "video": "https://youtu.be/hX-nt1KoYeM?t=80"
    },
    {
        "speciesId" : "anfi_molloy",
        "speciesName" :  "Eurythenes gryllus",
        "speciesScientificName" : "Eurythenes gryllus",
        "video": "https://youtu.be/KcLWZ2q4liw?t=171"
    }
]

const SpeciesImage = ({url}) => {
    return (<div style={{flex: "1 1 auto", width: "100%", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundImage: `url(${url})`}}>
    </div>)
}

const CreditsDialog = () => {
    return (
    <Dialog>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        }}>
            <h2>Créditos</h2>
            <div className="credits" style={{flex: "1 1 auto", overflowY: "scroll", width: "100%"}}>
                <h4>Director</h4>
                <p>Edwin Rodríguez</p>

                <h4>Idea original</h4>
                <p>Felipe Gamonal</p>
                <p>Edwin Rodríguez</p>

                <h4>Arte conceptual</h4>
                <p>Felipe Gamonal</p>

                <h4>Asesor de producción</h4>
                <p>Pablo Rosenblatt</p>

                <h4>Contenidos científicos</h4>
                <p>Bárbara Léniz</p>
                <p>Felipe Gamonal</p>

                <h4>Contenidos educativos</h4>
                <p>Bárbara Léniz</p>

                <h4>Comunicaciones</h4>
                <p>Aida Granell</p>

                <h4>Jugabilidad</h4>
                <p>Edwin Rodríguez</p>

                <h4>Programación</h4>
                <p>Edwin Rodríguez</p>
                <p>Marcelo Vargas</p>

                <h4>Animaciones</h4>
                <p>Edwin Rodríguez</p>

                <h4>Distribución</h4>
                <p>Labo86</p>

                <h4>Diseño de niveles</h4>
                <p>Edwin Rodríguez</p>
                <p>Marcelo Vargas</p>

                <h4>Composición musical</h4>
                <p>Omar García Márquez</p>

                <h4>Efectos de sonido</h4>
                <p>Omar García Márquez</p>

                <h4>Administración</h4>
                <p>Olga Pérez</p>

                <h4>Diseño y fabricación de stands</h4>
                <p>Balans Design</p>

                <h4>Distribución de banda sonora</h4>
                <p>Benjamín Guerra</p>
            </div>
            <RLink className="link-button-dialog" to={"/"}>Volver</RLink>
        </div>
    </Dialog>);
}

const SpeciesSpriteDialog = () => {

    //get route param
    const { speciesId } = useParams();

    //get species data
    const data = getSpeciesById(speciesId);


    return (
        <Dialog>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                maxWidth: "100%)"
            }}>
                <SpeciesImage url={`species/sprite/${speciesId}.png`}/>
                <div style={{fontSize: "0.7em"}}>
                    <h4 style={{marginBottom: "0em"}}>{data.speciesName}</h4>
                    <p style={{fontStyle: "italic", marginTop: "0em"}}>{data.speciesScientificName}</p>
                </div>
                <div>
                    <RLink className="link-button-dialog" to={`/species/${speciesId}/photo`}>Fotografía</RLink>
                    <RLink className="link-button-dialog" to={"/species"}>Volver</RLink>
                </div>
            </div>
        </Dialog>);
}

const SpeciesPhotoDialog = () => {

    //get route param
    const { speciesId } = useParams();

    //get species data
    const data = getSpeciesById(speciesId);


    return (
        <Dialog>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                maxWidth: "100%)"
            }}>
                <SpeciesImage url={`species/photo/${speciesId}_p.jpg`}/>
                <div style={{fontSize: "0.7em"}}>
                    <h4 style={{marginBottom: "0em"}}>{data.speciesName}</h4>
                    <p style={{fontStyle: "italic", marginTop: "0em"}}>{data.speciesScientificName}</p>
                </div>
                <div>
                    <a className="link-button-dialog" href={data.video} target="_blank" rel="noreferrer">Video</a>
                    <RLink className="link-button-dialog" to={`/species/${speciesId}/sprite`}>Volver</RLink>
                </div>
            </div>
        </Dialog>);
}

const SpeciesDialog = () => {
    return (
    <Dialog>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%"
        }}>
            <h2>Especies</h2>
            <div style={{flex: "1 1 auto", width: "100%", display: "flex", flexWrap: "wrap", overflowY: "scroll", justifyContent: "center"}}>
                {speciesList.map((species   ) =>
                    <RLink className={"species-box"} to={`/species/${species.speciesId}/sprite`}>
                        <img className={"species-img"} src={`species/sprite/${species.speciesId}.png`} alt={""}/>
                        <div style={{fontSize: "0.5em"}}>{species.speciesScientificName === "" ? species.speciesName : species.speciesScientificName}</div>
                    </RLink>)}
            </div>
            <div>
                <RLink className="link-button-dialog" to={"/"}>Volver</RLink>
            </div>
        </div>
    </Dialog>);
}

const PrivacyDialog = () => {
    return (
        <Dialog>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <h2>Política de privacidad</h2>
                <div style={{flex: "1 1 auto", width: "100%", overflowY: "scroll"}}>
                    <p>Última actualización: 5 de noviembre de 2022</p>
                    <p>Esta política de privacidad explica como tu información personal es recolectada y procesada por el Instituto Milenio de Oceanografía cuando usas nuestras aplicaciones móviles.</p>
                    <p>Te adelantamos que esta aplicación no recolecta información personal de ningún tipo, y por lo tanto, no hacemos uso de ningún tipo información personal. Además, esta aplicación es gratis y no contiene anuncios ni compras integradas.</p>
                    <p>Actualizaremos esta política de privacidad de vez en cuando. Si hacemos cambios, te notificaremos que revises la fecha al comienzo de esta política y, dependiendo los cambios, te proveeremos información adicional o requeriremos una renovación de tu consentimiento.</p>
                    <h4>Recolección de información</h4>
                    <p>La aplicación no recoge ningún tipo de información personal.</p>
                    <h4>Uso de la información</h4>
                    <p>Al no recolectar ningún tipo de información, no hacemos ningún uso de información que pueda vulnerar la privacidad del usuario.</p>
                    <h4>Almacenamiento de información</h4>
                    <p>La aplicacion almacena datos relacionados con el avance de los jugadores. Dichos datos están almacenados en la memoria del dispositivo del usuario y no se transmiten a ningún otro dispositivo o servidor externo.</p>
                    <h4>Compartir información</h4>
                    <p>No se comparte información del jugador a ninguna entidad.</p>
                    <h4>Redes sociales</h4>
                    <p>La aplicación no tiene ninguna característica de integración con redes sociales.</p>
                    <h4>Contacto</h4>
                        <p>El Instituto Milenio de Oceanografía tiene la siguiente información de contacto:</p>
                        <p>Corporación Instituto Milenio de Oceanografía<br/>
                        Dirección: Cabina 7, Barrio Universitario, Universidad de Concepción, Concepción, Chile<br/>
                            Email: contacto@imo-chile.cl<br/>
                            Teléfono: +56-41-2661266<br/>
                        </p>

                </div>
                <div>
                    <RLink className="link-button-dialog" to={"/"}>Volver</RLink>
                </div>
            </div>
        </Dialog>);
}

const ProxDialog = () => {
    return (
        <Dialog>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <h2>Próximamente</h2>
                <div style={{flex: "1 1 auto", width: "100%", display: "flex", flexWrap: "wrap", overflowY: "scroll", justifyContent: "center", alignItems: "center"}}>
                    Lanzamiento 22 de noviembre del 2022
                </div>
                <div>
                    <RLink className="link-button-dialog" to={"/"}>Volver</RLink>
                </div>
            </div>
        </Dialog>);
}

const ResourcesDialog = () => {
    return (
        <Dialog>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%"
            }}>
                <h2>Recursos</h2>
                <div style={{flex: "1 1 auto", width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", overflowY: "scroll", justifyContent: "center", alignItems: "center"}}>
                    <a className="link-button-dialog" href={"/docs/actividad.pdf"}>Actividad</a>
                    <a className="link-button-dialog" href={"/docs/guia_contenidos.pdf"}>Guía de contenidos</a>
                    <RLink className="link-button-dialog" to={"/species"}>Índice de especies</RLink>
                    <a className="link-button-dialog" href={"https://drive.google.com/file/d/1LFJSYhwbOGAMdq9PPTrDN8JNg5ckyYKk/view?usp=drivesdk"} target={"_blank"} rel="noreferrer">Beta para Android</a>
                </div>
                <div>
                    <RLink className="link-button-dialog" to={"/"}>Volver</RLink>
                </div>
            </div>
        </Dialog>);
}

function App() {
  return (
  <HashRouter>
    <div className="App">
        <WallLeft x={-10} y={1200} index={1}/>
        <WallLeft x={-10} y={500} index={2}/>
        <WallLeft x={-10} y={600} index={3}/>
        <WallLeft x={-10} y={750} index={4}/>
        <WallLeft x={-10} y={300} index={5}/>
        <WallLeft x={-10} y={1300} index={6}/>
        <WallLeft x={-10} y={700} index={7}/>
        <WallLeft x={-10} y={800} index={8}/>
        <WallLeft x={-10} y={1000} index={9}/>
        <WallLeft x={-10} y={1200} index={10}/>
        <WallLeft x={-10} y={500} index={11}/>
        <WallLeft x={-10} y={600} index={12}/>
        <WallLeft x={-10} y={750} index={1}/>
        <WallLeft x={-10} y={300} index={2}/>
        <WallLeft x={-10} y={1300} index={3}/>
        <WallLeft x={-10} y={700} index={4}/>
        <WallLeft x={-10} y={800} index={5}/>
        <WallLeft x={-10} y={1400} index={6}/>
        <WallLeft x={-10} y={1500} index={7}/>
        <WallLeft x={-10} y={1600} index={8}/>
        <WallLeft x={-10} y={1700} index={9}/>
        <WallLeft x={-10} y={1900} index={10}/>
        <WallLeft x={-10} y={2100} index={11}/>
        <WallLeft x={-10} y={2200} index={12}/>
        <WallLeft x={-10} y={2300} index={1}/>
        <WallLeft x={-10} y={2450} index={2}/>
        <WallLeft x={-10} y={2550} index={3}/>
        <WallLeft x={-10} y={2600} index={4}/>
        <WallLeft x={-10} y={2700} index={5}/>
        <WallLeft x={-10} y={2800} index={6}/>
        <WallLeft x={-10} y={2900} index={7}/>
        <WallLeft x={-10} y={3000} index={8}/>
        <WallLeft x={-10} y={3200} index={2}/>
        <WallLeft x={-10} y={3400} index={3}/>
        <WallLeft x={-10} y={2600} index={4}/>
        <WallLeft x={-10} y={2700} index={5}/>
        <WallLeft x={-10} y={2800} index={6}/>
        <WallLeft x={-10} y={2900} index={7}/>
        <WallLeft x={-10} y={3000} index={8}/>




        <WallRight x={-10} y={1300} index={1}/>
        <WallRight x={-10} y={500} index={2}/>
        <WallRight x={-10} y={600} index={3}/>
        <WallRight x={-10} y={750} index={4}/>
        <WallRight x={-10} y={300} index={5}/>
        <WallRight x={-10} y={1900} index={6}/>
        <WallRight x={-10} y={700} index={7}/>
        <WallRight x={-10} y={800} index={8}/>
        <WallRight x={-10} y={1000} index={9}/>
        <WallRight x={-10} y={1300} index={1}/>
        <WallRight x={-10} y={1450} index={2}/>
        <WallRight x={-10} y={1500} index={3}/>
        <WallRight x={-10} y={4650} index={4}/>
        <WallRight x={-10} y={2700} index={5}/>
        <WallRight x={-10} y={2900} index={6}/>
        <WallRight x={-10} y={2100} index={7}/>
        <WallRight x={-10} y={2500} index={8}/>
        <WallRight x={-10} y={2600} index={9}/>
        <WallRight x={-10} y={2600} index={1}/>
        <WallRight x={-10} y={2700} index={2}/>
        <WallRight x={-10} y={2800} index={3}/>
        <WallRight x={-10} y={2950} index={4}/>
        <WallRight x={-10} y={3000} index={5}/>
        <WallRight x={-10} y={3100} index={6}/>
        <WallRight x={-10} y={3200} index={7}/>
        <WallRight x={-10} y={3400} index={8}/>
        <WallRight x={-10} y={3600} index={9}/>

            <Routes>
                <Route exact path="/species" element={<SpeciesDialog/>}/>
                <Route path={"/species/:speciesId/sprite"} element={<SpeciesSpriteDialog/>}/>
                <Route path={"/species/:speciesId/photo"} element={<SpeciesPhotoDialog/>}/>
                <Route path="/credits" element={<CreditsDialog/>}/>
                <Route path="/privacy" element={<PrivacyDialog/>}/>
                <Route path="/prox" element={<ProxDialog/>}/>
                <Route path="/resources" element={<ResourcesDialog/>}/>
            </Routes>


        <div class="title-grid">
        <div style={{gridArea: "title", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
            <div class={"title"} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "10%",}}>
                <img src={logo} width="70%" style={{imageRendering: "pixelated"}} alt="logo" />
            </div>
            <div style={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%"
            }}>
                <a style={{margin: "1%", width: "33%"}} href={"https://play.google.com/store/apps/details?id=com.Imo.Audacia"} rel="noreferrer" target={"_blank"}>                <img
                    src={"google-play-badge.png"}
                    width={"100%"}
                    alt={"Vínculo a Google play"}
                /></a>
                <a style={{margin: "1%", width: "30%"}} href={"https://apps.apple.com/us/app/audacia-desaf%C3%ADo-hadal/id6443837238"} rel="noreferrer" target={"_blank"}>                               <img
                    src={"app-store-badge.png"}
                    width={"100%"}
                    alt={"Vínculo a la App store"}
                /></a>
                {/*}
                <img
                    style={{margin: "1%"}}
                    width={"33%"}
                    src={"google-play-badge.png"}
                />
                <img
                    style={{margin: "1%"}}
                    width={"30%"}
                    src={"app-store-badge.png"}
                />
                */}
            </div>
        </div>

        <div class={"cellphone"} style={{gridArea: "cellphone", height: "100vh", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
          <Cellphone></Cellphone>
        </div>
        </div>
        <img className="mount mount1" width="100%" src={"mount/mount_1.png"} alt={""}
             style={{position: "fixed",imageRendering: "pixelated", left:0}}/>
        <img className="mount mount2" width="100%" src={"mount/mount_2.png"} alt={""}
             style={{position: "fixed", imageRendering: "pixelated", left: 0}}/>
        <img className="mount mount3" width="100%" src={"mount/mount_3.png"} alt={""}
             style={{position: "fixed", imageRendering: "pixelated", left: 0}}/>
        <img className="mount mount4" width="100%" src={"mount/mount_4.png"} alt={""}
             style={{position: "fixed", imageRendering: "pixelated", left: 0}}/>

        <div className="super_container_1">
            <div className="container">
                <div className="respSection respSectionLeft">
                    <div>
                        <img width={"60%"} className="animal" src={"sprites/anfi_atacama.png"} alt={""}/>
                    </div>
                    <div>
                        <div style={{padding: "10%"}}>
                            <h2>Investiga especies</h2>
                            <p>Descubre más de 30 especies de organismos de profundidad. Sumérgete con la ayuda del lander en las fosas oceánicas y descubre los misterios que esconden las profundidades hadales.</p>
                        </div>
                        <RLink className="link-button" to={"/species"}>¡Descúbrelas!</RLink>
                    </div>
                </div>
            </div>
            <div className="super_container_1">
                <div className="container">
                    <div className="respSection respSectionRight">
                        <div>
                            <img width={"60%"} className="animal" src={"sprites/calamar_vampiro.png"} alt={""}/>
                        </div>
                        <div>
                            <div style={{padding: "10%"}}>
                            <h2>7 profundidades</h2>
                                <p>Explora las fosas oceánicas más importantes del planeta, y especialmente la Fosa de Atacama.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="super_container_1">
                    <div className="container">
                        <div className="respSection respSectionLeft">
                            <div>
                                <img width={"50%"} className="lander" src={"sprites/sprite.png"} alt={""}/>
                            </div>
                            <div>
                                <div style={{padding: "10%"}}>
                                <h2>Mejora tu equipo</h2>
                                    <p>Prepárate para la aventura con tu sumergible. Equípalo con diferentes sensores y lánzate a la exploración.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="super_container_1">
                        <div className="container">
                            <div className="respSection respSectionRight">
                                <div>
                                    <img width={"80%"} className="cd" src={"cover_art2.jpg"} alt={"Cubierta de banda sonora"}/>
                                </div>
                                <div>
                                    <div style={{padding: "10%"}}>
                                        <h2>Inmersiva banda sonora</h2>
                                        <p>Escucha la banda sonora, ya disponible en diferentes plataformas como Spotify y Youtube Music, entre otros.</p>
                                    </div>

                                    <a className="link-button"  rel="noreferrer" href={"https://open.spotify.com/album/4siY9ElzyytMMur9DW7N5R?si=h4cqtX7wSWCHPhPzSNAWnw"} target="_blank">¡Escuchar!</a>
                                </div>
                            </div>
                        </div>
                        <div className="super_container_1">
                            <div className="container">
                                <div className="respSection respSectionLeft">
                                    <div >
                                        <img className="animal" src={"sprites/pez_dumbo_pequeño.png"} alt={""}/>
                                    </div>
                                    <div>
                                        <div style={{padding: "10%"}}>
                                            <h2>Uso en aula</h2>
                                            <p>Convierte “Audacia: Desafío Hadal”, gracias a nuestros recursos educativos, en una valiosa herramienta pedagógica con la que complementar los contenidos en el aula.</p>
                                        </div>
                                        <RLink className="link-button"  to={"/resources"}>¡Obtener!</RLink>
                                    </div>
                                </div>
                            </div>
                            <div className="super_container_1">
                                <div className="container">
                                    <div className="respSection respSectionRight">
                                        <div >
                                            <img className="animal" src={"sprites/pez_rape.png"} alt={""}/>
                                        </div>
                                        <div>
                                            <div style={{padding: "10%"}}>
                                                <h2>Nuestro equipo</h2>
                                                <p>El videojuego está elaborado por un grupo de profesionales multidisciplinar.</p>
                                            </div>
                                            <RLink className="link-button" to={"/credits"}>¡Conócenos!</RLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="super_container_1">
                                    <div className="container">
                                        <div className="respSection respSectionLeft">
                                            <div >
                                                <img className="animal" src={"species/sprite/japetella_diaphana.png"} alt={""}/>
                                            </div>
                                            <div>
                                                <div style={{padding: "10%"}}>
                                                    <h2>Privacidad</h2>
                                                    <p>Conoce como nuestra aplicación protege tus datos personales.</p>
                                                </div>
                                                <RLink className="link-button" to={"/privacy"}>Política</RLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="super_container_1">
                                        <div className="container">
                                            <div className="logos">

                                                <img src={"logo_cp.png"} alt={"Logo de Ciencia Pública"}/>
                                                <img src={"milenio.png"} alt={"Logo de la iniiativa Milenio"}/>
                                                <img src={"imo1767x989.png"} alt={"Logo del Instituto Milenio de Ocenaografía"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </HashRouter>
  );
}

export default App;
