


const Dialog = ({children}) => {
    return (
        <div style={{position: "fixed", top: 0, bottom: 0, left: 0, right: 0, padding: "2em", zIndex: 1000, backgroundColor: "rgba(0.0, 0.0, 0.0, 0.9)" }}>
            {children}
        </div>
    )
}

export {Dialog};