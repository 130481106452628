
const WallLeft = function ({x, y, index}) {
    console.log(index);
    const wall = `wall/wall_${index}.png`;



    return <div>
        <img
            src={wall}
            style={{ position: 'absolute',
                zIndex: -10,
                transform: "scale(200%)",
                left: x, top: y,
                imageRendering: "pixelated"}}
        />
    </div>
}

const WallRight = function ({x, y, index}) {
    console.log(index);
    const wall = `wall/wall_${index}.png`;

    return <div>
        <img
            src={wall}
            style={{ position: 'absolute',
                transform: "scale(200%)",
                zIndex: -10,
                right: x, top: y,
                imageRendering: "pixelated"}}
        />
    </div>
}

const Cellphone = function() {
    return <div style={{ position: "relative", width: "40vh", height : "80vh" }}>
        {/*<iframe frameBorder={0} width="95%" height="95%" style={{position: "absolute", paddingTop: "5%", paddingBottom: "5%", paddingLeft: "2.5%", paddingRight: "2.5%", left:0}}
                src="https://www.youtube.com/embed/_H5GGGZe2MA?autoplay=1&mute=1">
        </iframe>*/}
        {
        <video muted autoPlay loop width={"95%"} height={"95%"} style={{position: "absolute", paddingTop: "5%", paddingBottom: "5%", paddingLeft: "2.5%", paddingRight: "2.5%", left:0}} playsInline>
            <source src={"intro.webm"} type={"video/webm"}/>
        </video>
        }
        <img
            height={"100%"}
            src={"cellphone.png"}
            style={{ position: 'absolute', left: 0}}
        />

    </div>
}

export { WallLeft, WallRight, Cellphone };